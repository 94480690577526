import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { graphql, PageProps } from 'gatsby';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { RichText } from '../components/rich-text';
import { ContentfulSettings } from '../contentful';
import { useAppSelector } from '../hooks';
import { RootState } from '../store';

interface AccountPageQueryProps {
  contentfulSettings: ContentfulSettings;
}

type AccountPageType = PageProps<AccountPageQueryProps>;

const AccountPage = ({ data }: AccountPageType) => {
  const email = useAppSelector((state: RootState) => state.user.email);
  const { logout } = useAuth0();

  const [requestOk, setRequestOk] = useState<boolean>(false);

  const resetPassword = async () => {
    const url = new URL(
      `${window.location.protocol}//${
        process.env.GATSBY_AUTH0_DOMAIN || process.env.AUTH0_DOMAIN
      }/dbconnections/change_password`,
    );

    const requestBody = {
      client_id:
        process.env.GATSBY_AUTH0_CLIENTID || process.env.AUTH0_CLIENTID,
      email,
      connection: 'Username-Password-Authentication',
    };

    const options: RequestInit = {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: new Headers({
        'Content-type': 'application/json; charset=utf-8',
      }),
    };

    const response = await fetch(url, options);

    setRequestOk(response.ok);
  };

  const dialogStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: '#FFF',
    border: '1px solid #000',
    boxShadow: 24,
    padding: '2em',
  };

  return (
    <>
      <Helmet title={data.contentfulSettings.ecomCommon.accountPage.title} />
      <Typography component="h1" variant="h1" sx={{ my: 4 }}>
        {data.contentfulSettings.ecomCommon.accountPage.title}
      </Typography>
      {!requestOk && (
        <Button onClick={() => resetPassword()} variant="contained">
          {data.contentfulSettings.ecomCommon.accountPage.changePasswordButton}
        </Button>
      )}

      <Modal
        open={requestOk}
        aria-labelledby="konto-title"
        aria-describedby="konto-description"
      >
        <Box sx={dialogStyle}>
          <Typography id="konto-title" variant="h6" component="h2">
            {data.contentfulSettings.ecomCommon.accountPage.title}
          </Typography>
          <Typography id="konto-description" sx={{ mt: 2 }} component="p">
            <RichText
              data={{
                richText: {
                  raw: data.contentfulSettings.ecomCommon.accountPage
                    .resetPasswordMessage.raw,
                  references: [],
                },
              }}
            />
          </Typography>
          <Box sx={{ display: 'flex', mt: '2em', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Till inloggning
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export const query = graphql`
  query {
    contentfulSettings {
      ecomCommon {
        ...EcomCommon
      }
    }
  }
`;
export default AccountPage;
